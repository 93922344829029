<template>
  <div class="adminVideo">
    <div class="adminVideo_top">
      <VideoTitle title="视频管理">
        <el-input placeholder="搜索你想要的视频" v-model="inputSearch" @change="changeSearch">
          <i slot="suffix" class="el-input__icon el-icon-search" @click="videoSearch(inputSearch)"></i>
        </el-input>
      </VideoTitle>
    </div>
    <div class="flex adminVideo_conter">
      <span class="tab">状态：</span>
      <ul class="tab-tilte">
        <!-- 方法一： class对象-->
        <!-- <li v-for="(title,index) in tabTitle" @click="cur=index" :class="{active:cur==index}">{{title}}</li> -->

        <!-- 方法二： class数组+三元运算-->
        <li v-for="(title, index) in tabTitle" :key="index" @click="check(index)" :class="[cur == index ? 'active' : '']">
          {{ title }}
        </li>
      </ul>
    </div>
    <div v-if="listDataAll.length">
      <div v-for="(item, index) in listDataAll" :key="index" class="flex adminVideo_conter">
        <div v-if="item.type" class="left_img" @click="clickListVideo(item.videoTitle, item.videoPlayUrl, item.videoState)">
          <el-image :src="handleUrl(item.videoImg, item.videoPlayUrl)" :class="{ noVideo: !item.videoPlayUrl }">
            <div slot="placeholder" class="image-slot">
              加载中
              <span class="dot">...</span>
            </div>
          </el-image>
          <span>{{ secondToTimeStr(item.videoDuration) }}</span>
        </div>
        <div v-else class="left_img" @click="clickListVideo(item.videoTitle, item.videoEditingPlayUrl, item.videoState)">
          <el-image :src="handleUrl(item.videoEditingImg, item.videoEditingPlayUrl)" :class="{ noVideo: !item.videoEditingPlayUrl }">
            <div slot="placeholder" class="image-slot">
              加载中
              <span class="dot">...</span>
            </div>
          </el-image>
          <span>{{ secondToTimeStr(item.videoEditingDuration) }}</span>
        </div>
        <div class="column right">
          <div class="right_top">
            <div class="right_tltle">{{ item.videoTitle }}</div>
            <div class="flex icon_text">
              <div class="fac">
                <img src="../../assets/img/creatorPlatform/suspend.png" />
                <span>{{ item.videoPlayNum }}</span>
              </div>
              <div class="fac">
                <img src="../../assets/img/creatorPlatform/like.png" />
                <span>{{ item.videoLikeNum }}</span>
              </div>
              <div class="fac">
                <img src="../../assets/img/creatorPlatform/info.png" />
                <span>{{ item.comment }}</span>
              </div>
              <div class="fac">
                <img src="../../assets/img/creatorPlatform/data.png" />
                <span>{{ item.giveReward }}</span>
              </div>
            </div>
            <span>发布时间:{{ item.videoCreateTime }}</span>
            <div class="state">
              视频状态：
              <span v-if="item.soldOut == 1">已下架 {{ item.statesDescribe }}</span>
              <span v-else>{{ videoState(item.videoState) }}
                {{ item.statesDescribe }}</span>
            </div>
          </div>
          <div class="flex right_botton">
            <div class="fcc" @click="editVideo(item)">
              <img src="../../assets/img/creatorPlatform/eidt.png" />
              <span>编辑视频</span>
            </div>
            <div class="fcc" @click="managementQuestions(item)">
              <img src="../../assets/img/creatorPlatform/ask.png" />
              <span>管理提问</span>
            </div>
            <div v-if="item.videoState == 1 && index" class="fcc" @click="topVideo(index, listDataAll)">
              <img src="../../assets/img/creatorPlatform/top.png" />
              <span>置顶视频</span>
            </div>
            <!-- <div v-else class="fcc display">
              <img src="../../assets/img/creatorPlatform/top.png" />
              <span>置顶视频</span>
            </div>-->
          </div>
        </div>
      </div>
      <Pagination :pageTabel="listQurey" @pagingChange="pagingChange"></Pagination>
    </div>
    <el-empty v-else :image-size="200" description="暂无数据"></el-empty>
    <el-dialog v-if="dialogVisible" :title="dataListVideo.videoTitle || '视频'" :visible.sync="dialogVisible" class="video_dialog" :append-to-body="true">
      <video controls autoplay :src="dataListVideo.videoPlayUrl"></video>
    </el-dialog>
  </div>
</template>

<script>
import VideoTitle from "@/components/public/VideoTitle.vue";
import Pagination from "@/components/public/Pagination.vue";
import {
  videoAllDataByWeb,
  originatorSearchVideoByPage,
  videoStick,
} from "@/utils/serveApi";
import {
  videoState,
  handleImage,
  secondToTimeStr,
  videoStates,
} from "@/utils/public";
export default {
  name: "adminVideo",
  data() {
    return {
      activeIndex: 0,
      inputSearch: "",
      dialogVisible: false,
      listDataAll: [],
      dataListVideo: {},
      listQurey: {
        key: "",
        pageNum: 1,
        pageSize: 6,
        typeOne: 0,
        typeTwo: 0,
        isC: 0,
        cid: 0,
        total: 0,
      },
      tabTitle: ["全部", "已通过", "未通过", "审核中", "已下架"],//, "草稿"
      cur: 0, //默认选中第一个tab
      handleImages: "",
      listDefaultQurey: {},
    };
  },
  created() {
    this.listDefaultQurey = { ...this.listQurey };
    this.getDataList();
  },
  methods: {
    check: function (index) {
      this.cur = index;
      switch (index) {
        case 0:
          this.listQurey.typeOne = 0;
          break;
        case 1:
          this.listQurey.typeOne = 2;
          break;
        case 2:
          this.listQurey.typeOne = 3;
          break;
        case 3:
          this.listQurey.typeOne = 1;
          break;
        case 4:
          this.listQurey.typeOne = 4;
          break;
        default:
          this.listQurey.typeOne = 5;
          break;
      }
      this.listQurey.pageNum = 1;

      this.getDataList();
    },
    getDataList() {
      videoAllDataByWeb(this.listQurey).then((res) => {
        if (res && res.stateCode == 1) {
          const { video, total } = res.data;
          this.listDataAll = video;
          this.listQurey.total = total;
        }
      });
    },
    videoState(index) {
      return videoState(index);
    },
    topVideo(index, rows) {
      const { videoId } = rows[index];
      videoStick({ videoId, type: 1 }).then((res) => {
        if (res.success) {
          rows.map((item, i) => {
            if (i == index) rows.unshift(rows.splice(index, 1)[0]);
          });
        }
      });
    },
    managementQuestions(item) {
      this.$router.push({
        name: "managementQuestions",
        query: {
          videoId: item.videoId,
        },
      });
    },
    editVideo({ videoState, videoId }) {
      if (videoState != 1 && videoState != 99 && videoState != 2) {
        this.$message(videoStates[videoState]);
        return;
      }
      this.$router.push({
        name: "postVideo",
        query: { videoId, editVideo: true },
      });
    },
    videoSearch(value) {
      this.listQurey.key = value;
      originatorSearchVideoByPage(this.listQurey).then((res) => {
        const { videos, total } = res.data;
        this.listDataAll = videos;
        this.listQurey.total = total;
      });
    },
    changeSearch(value) {
      if (!value) {
        this.listQurey = { ...this.listDefaultQurey };
        this.getDataList();
      }
    },
    pagingChange(o) {
      this.listQurey.pageNum = o.pageNum;
      this.getDataList();
    },
    clickListVideo(title, url, state) {
      if (state == 0 || state == 3) {
        this.$message("视频审核中...");
        return;
      }
      if (!url) return;
      this.dataListVideo.videoTitle = title || "视频";
      this.dataListVideo.videoPlayUrl = url;
      this.dialogVisible = true;
    },
    secondToTimeStr(t) {
      return secondToTimeStr(t);
    },
  },
  components: { VideoTitle, Pagination },
  computed: {
    handleUrl() {
      return (imgUrl, videoUrl) => {
        if (imgUrl) return imgUrl;
        if (!videoUrl) return imgUrl;
        handleImage(videoUrl, (img) => (this.handleImages = img));
        return this.handleImages;
      };
    },
  },
};
</script>
<style lang="scss">
.adminVideo {
  &_top {
    .el-input {
      .el-input__inner {
        background: #eef2f5;
        border: 1px solid #c1c1c1;
        border-radius: 22px;
      }
      .el-icon-search {
        cursor: pointer;
      }
    }
  }
}
.video_dialog {
  .el-dialog__title,
  .el-icon-close {
    font-size: 20px;
  }
}

.tab {
  padding: 10px 0;
  width: 10%;
  font-weight: bold;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tab-tilte {
  width: 100%;
}

.tab-tilte li {
  float: left;
  width: 10%;
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
}

/* 点击对应的标题添加对应的背景颜色 */
.tab-tilte .active {
  //background-color: #09f;
  color: rgb(192, 158, 7);
}
</style>
<style lang="scss" scoped>
.adminVideo {
  &_top {
    .el-input {
      width: 574px;
      height: 40px;
      margin-right: 40px;
    }
  }
  &_conter {
    padding: 38px 40px;
    .left_img {
      width: 320px;
      min-width: 320px;
      margin-right: 24px;
      position: relative;
      img,
      .el-image {
        width: 100%;
        height: 200px;
        border-radius: 10px;
        object-fit: cover;
        cursor: pointer;
      }
      .noVideo {
        cursor: no-drop;
      }
      span {
        position: absolute;
        bottom: 16px;
        right: 16px;
        width: 52px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        display: block;
        background: #9a9a9a;
        border-radius: 4px;
        font-size: 14px;
      }
    }
    .right {
      &_tltle {
        font-size: 20px;
        color: #000000;
      }
      &_top {
        color: #999999;
        .icon_text {
          margin: 10px 0;
          img {
            width: 18px;
            height: 18px;
          }
          span {
            font-size: 18px;
            padding: 0 20px 0 8px;
          }
        }
        & > span {
          font-size: 16px;
        }
        .state {
          margin: 10px 0;
          font-size: 16px;
          & > span {
            color: #ff0000;
          }
        }
      }
      &_botton {
        margin-bottom: 5px;
        & > div {
          width: 118px;
          height: 36px;
          background: #eef2f5;
          border-radius: 21px;
          cursor: pointer;
          margin-right: 24px;
          img {
            width: 16px;
            height: 16px;
          }
          span {
            font-size: 16px;
            color: #222222;
            line-height: 0;
            padding-left: 6px;
          }
        }
        .display {
          cursor: no-drop;
          span {
            color: #cccccc;
          }
        }
      }
    }
  }
}
.video_dialog {
  ::v-deep .el-dialog {
    width: 60%;
    height: 600px;
    border-radius: 0;
    margin-top: 10vh !important;
    .el-dialog__body {
      box-sizing: border-box;
      height: 600px;
      background-color: #fff;
      display: flex;
      padding-bottom: 40px;
    }
    video {
      max-width: 100%;
      height: 100%;
      margin: auto;
      background-color: #000;
      // width: 100%;
      // max-width: 100%;
      // margin: $lh20 0;
    }
  }
}
</style>
